.button-logout {
  height: 60px;
  width: 100%;
  background: transparent;
  color: rgba(255, 255, 255, 0.9);
  font-size: 0.75em;
  text-decoration: none;
  grid-gap: 5px;
  gap: 5px;
  border-bottom: 1px solid;
  border-color: #10100f;
  padding: 17px;

  display: flex;
  align-items: center;
}

.buttonLogout:hover {
  background-color: #030302;
  transition: all 0.6s ease;
}

.button-logout span.icon {
  width: 30px;
  text-align: right;
}

.button-logout svg {
  fill: rgba(255, 255, 255, 0.9) !important;
}
